import { defineStore } from 'pinia'

export const useUserStore = defineStore('UserStore', {
  state() {
    return {
      user: null,
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
  },

  actions: {
    login(name, password) {
      if (name === 'Stone' && password === 'BAS') {
        this.user = {
          name: name,
          password: password,
        }
      }

      if (name === 'Sydney' && password === 'BAS') {
        this.user = {
          name: name,
          password: password,
        }
      }
    },
  },
})
