<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="logo" class="my-3" contain height="200" />
      </v-col>
    </v-row>
    <v-card class="cardMargin">
      <v-row>
        <v-col cols="8" offset="2">
          <h2 class="textMargin">Cage Schedule Login</h2>
          <v-text-field
            v-model="name"
            outlined
            label="User Name"
          ></v-text-field>

          <v-text-field
            v-model="password"
            outlined
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toogleShowPassword"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8" offset="2">
          <v-btn @click="login" color="primary">Login</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { useUserStore } from '@/store/UserStore'
import logo from '@/assets/images/5Logo.svg'

export default{
  data: () => ({
    name: null,
    password: null,
    logo: logo,
    showPassword: false,
  }),

  setup() {
    const userStore = useUserStore()

    return {
      userStore,
    }
  },

  methods: {
    login() {
      this.userStore.login(this.name, this.password)
      this.$router.push({ name: 'CageSchedule' })
    },

    toogleShowPassword() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>

<style scoped>
.cardMargin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.textMargin {
  margin-bottom: 10px;
}
</style>
